import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Ok2LandLogo from '../resources/images/ok2land.png';
import LeftArrow from '../resources/images/left-arrow.svg';
import deleteSchedule from '../resources/images/delete-schedule.png';
import { Toggle } from '../helpers/toggle.js';
import Select from 'react-select'
import { getServices, getCategories } from '../helpers/getinfo.js';
import { createContact } from '../helpers/postinfo.js';
import config from '../global.js';
import axios from 'axios';


function SignUp() {
    /* Handle signup */
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [clubName, setClubName] = useState('');
    const [icao, setIcao] = useState('');
    const [fiscalAddress, setFiscalAddress] = useState('');
    const [nif, setNif] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phone, setPhone] = useState('');
    const [openTime, setOpenTime] = useState('');
    const [closeTime, setCloseTime] = useState('');
    const [error, setError] = useState(null);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isAddActive, setIsAddActive] = useState(false);
    const [addScheduleFlag, setAddScheduleFlag] = useState(true);
    const [selectedDays, setSelectedDays] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [runwayTypes, setRunwayTypes] = useState([]);
    const [runwayType, setRunwayType] = useState(0);
    const [runwayLat, setRunwayLat] = useState('');
    const [runwayLong, setRunwayLong] = useState('');
    const [heliLat, setHeliLat] = useState('');
    const [heliLong, setHeliLong] = useState('');
    const [direction, setDirection] = useState('');
    const [elevation, setElevation] = useState('');
    const [length, setLength] = useState('');
    const [weightLimit, setWeightLimit] = useState('');
    const [requestLand, setRequestLand] = useState(false);
    const [price05, setPrice05] = useState('');
    const [price05_1, setPrice05_1] = useState('');
    const [price1_2, setPrice1_2] = useState('');
    const [price2_3, setPrice2_3] = useState('');
    const [price3_4, setPrice3_4] = useState('');
    const [price4, setPrice4] = useState('');
    const [priceHourSingle, setPriceHourSingle] = useState('');
    const [priceHourTwin, setPriceHourTwin] = useState('');
    const [priceHourBizz, setPriceHourBizz] = useState('');
    const [priceHourHeli, setPriceHourHeli] = useState('');
    const [priceHoursFree, setPriceHoursFree] = useState('');
    const [priceOvernight1, setPriceOvernight1] = useState('');
    const [priceOvernight2, setPriceOvernight2] = useState('');
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [stripeEmail, setStripeEmail] = useState('');

    // Get landings info
    useEffect(() => {
        async function fetchData() {
            try {
                const servicesData = await getServices();
                console.log('services', servicesData);
                if(servicesData.length > 0){
                setServices(servicesData);
                }
            } catch (error) {
                console.error('Error getting landings info: ', error);
            }
            try {
                const data = await getCategories();
                const arrayCategories = data.data.map(item => ({
                    value: item.id,
                    label: capitalizeFirstLetter(item.attributes.name)
                }));
                setRunwayTypes(arrayCategories);
            } catch (error) {
                console.error('Error getting landings info: ', error);
            }
        }

        fetchData();
    }, []);

    const selectStyle = {
        control: (base, state) => ({
            ...base,
            height: '50px',
            minHeight: '50px',
            backgroundColor: state.hasValue ? '#FFFFFF' : '#DBF1F599',
            borderRadius: 2,
            borderWidth: 0,
            marginBottom: 10,
            boxShadow: state.isFocused ? 0 : base.boxShadow,
          }),
          input: (base) => ({
            ...base,
            height: '50px',
            margin: 0, // Pode ajustar as margens se necessário para alinhar corretamente
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)', // Centralizar verticalmente
          }),
          valueContainer: (base) => ({
            ...base,
            height: '50px',
            padding: '0px 10px', // Ajustar padding se necessário
          }),
    };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    // Step 1
    useEffect(() => {
        if(email !== '' && username !== ''){
            setIsButtonActive(true);
        }
        else{
            setIsButtonActive(false);
        }
    }, [email, username]);

    // Step 2
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        e.target.value === confirmpassword ? setIsButtonActive(true) : setIsButtonActive();
    };

    const handlePasswordConfirm = (e) => {
        setConfirmPassword(e.target.value);
        e.target.value === password ? setIsButtonActive(true) : setIsButtonActive();
    };

    // Step 3
    const handleClubName = (e) => {
        setClubName(e.target.value);
        updateButtonState(e.target.value);
    };

    const handleIcao = (e) => {
        setIcao(e.target.value);
        updateButtonState(e.target.value);
    };

    const handleFiscalAddress = (e) => {
        setFiscalAddress(e.target.value);
        updateButtonState(e.target.value);
    };

    const handleNif = (e) => {
        setNif(e.target.value);
        updateButtonState(e.target.value);
    };

    const handleContactPerson = (e) => {
        setContactPerson(e.target.value);
        updateButtonState(e.target.value);
    };

    const handlePhone = (e) => {
        setPhone(e.target.value);
        updateButtonState(e.target.value);
    };

    // Step 4

    const handleOpenTime = (e) => {
        setOpenTime(e.target.value);
    };

    const handleCloseTime = (e) => {
        setCloseTime(e.target.value);
    };

    useEffect(() => {
        console.log(selectedServices);
    }, [selectedServices]);

    useEffect(() => {
        if(selectedDays.length > 0 && openTime !== '' && closeTime !== ''){
            setIsAddActive(true);
        }
        else{
            setIsAddActive(false);
        }
    }, [selectedDays, openTime, closeTime]);

    const selectDayWeek = (i) => {
        if(!schedules.some(schedule => schedule.days.includes(i))){
            if (selectedDays.includes(i)) {
                const newArray = selectedDays.filter(item => item !== i);
                setSelectedDays(newArray);
            } else {
                setSelectedDays([...selectedDays, i]);
            }
        }
    };

    const addSchedule = () => {
        const jsonObject = {'days': selectedDays, 'openTime': openTime, 'closeTime': closeTime};
        var newArray = [...schedules, jsonObject];
        setSchedules(newArray);
        setSelectedDays([]);
        setOpenTime('');
        setCloseTime('');
        if(newArray.some(schedule => schedule.days.includes(0)) && newArray.some(schedule => schedule.days.includes(1)) && newArray.some(schedule => schedule.days.includes(2)) && newArray.some(schedule => schedule.days.includes(3)) && newArray.some(schedule => schedule.days.includes(4)) && newArray.some(schedule => schedule.days.includes(5)) && newArray.some(schedule => schedule.days.includes(6))){
            setIsButtonActive(true);
            setAddScheduleFlag(false);
        }
    };

    const handleDeleteSchedule = (index) => {
        const newArray = [...schedules];
        newArray.splice(index, 1);
        setSchedules(newArray);
        setAddScheduleFlag(true);
    };

    //step 5

    const handleRequestLand = state => {
        if(state) setRequestLand(true);
        else setRequestLand(false);
    }

    const handleRunwayType = (value) => {
        setRunwayType(value.value);
    }

    useEffect(() => {
        if(runwayType !== 0 && runwayLat !== '' && runwayLong !== '' && direction !== '' && elevation !== '' && length !== '' && weightLimit !== ''){
            setIsButtonActive(true);
        }
        else{
            setIsButtonActive(false);
        }
    }, [runwayType, runwayLat, runwayLong, direction, elevation, length, weightLimit]);

    //step 6

    useEffect(() => {
        if((price05 !== '' || price05_1 !== '' || price1_2 !== '' || price2_3 !== '' || price3_4 !== '' || price4 !== '') && (priceHourSingle !== '' || priceHourTwin !== '' || priceHourBizz !== '' || priceHourHeli !== '' || priceHoursFree !== '')){
            setIsButtonActive(true);
        }
        else{
            setIsButtonActive(false);
        }
    }, [price05, price05_1, price1_2, price2_3, price3_4, price4, priceHourSingle, priceHourTwin, priceHourBizz, priceHourHeli, priceHoursFree, priceOvernight1, priceOvernight2]);

    //step 7

    const handleService = id => {
        if (selectedServices.some(item => item.id === id)) {
            setSelectedServices((prev) => prev.filter((i) => i.id !== id));
        } else {
            const serviceArray = {
                id: id,
                price: 0
            }
            setSelectedServices((prev) => [...prev, serviceArray]);
        }
    }

    const handleServicePrice = (id, value) => {
        var newArray = selectedServices;
        const index = newArray.findIndex(service => service.id === id);
        
        if (index !== -1) {
            newArray[index].price = value;
        }
        setSelectedServices(newArray);
    };

    // Update button state
    const updateButtonState = (newEmail) => {
        setIsButtonActive(newEmail.trim() !== '');
        const nextBtn = document.getElementById("next__btn");
        if (nextBtn) {
            nextBtn.disabled = !isButtonActive;
        }
    };

    // Check step
    const checkNextStep = () => {
        setStep(step + 1);
        if(step+1 < 7){
            const nextBtn = document.getElementById("next__btn");
            if (nextBtn) {
                nextBtn.disabled = isButtonActive;
            }
            setIsButtonActive(false);
        }
    };

    const checkBackStep = () => {
        setStep(step - 1);
        const nextBtn = document.getElementById("next__btn");
        if (nextBtn) {
            nextBtn.disabled = !isButtonActive;
        }
        setIsButtonActive(true);
    };

    const handleSubmit = async () => {
        try {
            const responseUser = await axios.post(`${config.baseUrl}auth/local/register`, {
                username: username,
                email: email,
                password: password,
            });
            
            if (responseUser.status === 200 && responseUser.data.jwt) {
                const token = responseUser.data.jwt;
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                //localStorage.setItem('ok2landJwtToken', token);
                //localStorage.setItem('ok2landUserId', responseUser.data.user.id);
                var heliLatitude = heliLat;
                var heliLongitude = heliLong;
                if(heliLat == '' || isNaN(heliLat)){
                    heliLatitude = 0; 
                }
                if(heliLong == '' || isNaN(heliLong)){
                    heliLongitude = 0; 
                }
                const responseClub = await axios.post(`${config.baseUrl}clubs`, {
                    data: {
                        name1: clubName,
                        address: fiscalAddress,
                        category: runwayType,
                        nif: nif,
                        request: requestLand,
                        direction: direction,
                        weight: weightLimit,
                        length: length,
                        price_land: price05,
                        price_touch: price05_1,
                        price_park: priceHourSingle,
                        price_park_twin: priceHourTwin,
                        price_park_bizz: priceHourBizz,
                        price_park_heli: priceHourHeli,
                        hours_free_park: priceHoursFree,
                        lat: runwayLat,
                        long: runwayLong,
                        helipad_latitude: heliLatitude,
                        helipad_longitude: heliLongitude,
                        long: runwayLong,
                        altitude: elevation,
                        icao: icao,
                        contact_name: contactPerson,
                        phone: phone,
                        email: email,
                        stripeEmail: stripeEmail == '' ? null : stripeEmail,
                        user: responseUser.data.user.id
                    }
                }, { headers });
                if (responseClub.status === 200) {
                    for (const service of selectedServices) {
                        console.log(JSON.stringify(responseClub));
                        const responseClubService = await axios.post(`${config.baseUrl}club-services`, {
                            data: {
                                club: responseClub.data.data.id,
                                service: service.id,
                                price: service.price
                            }
                        }, { headers });
                        
                        if (responseClubService.status !== 200) {
                            throw new Error('Erro ao criar club service.');
                        }
                    }
                    const dayNames = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
                    for (const schedule of schedules) {
                        console.log('schedule', JSON.stringify(schedule));
                        for (const day of schedule.days) {
                            console.log('day', day);
                            const data = {
                                data: {
                                    club: responseClub.data.data.id,
                                    day_index: day,
                                    day: dayNames[day],
                                    open: schedule.openTime,
                                    close: schedule.closeTime,
                                },
                            };
            
                            // Busca por entradas existentes
                            const searchResponse = await axios.get(`${config.baseUrl}schedules?filters[club][id][$eq]=${responseClub.data.data.id}&filters[day_index][$eq]=${day}`, { headers });
                            const existingEntries = searchResponse.data;
                            if (existingEntries.data.length > 0) {
                                // Se encontrar, faz um PUT para atualizar a primeira entrada encontrada
                                const entryId = existingEntries.data[0].id;
                                await axios.put(`${config.baseUrl}schedules/${entryId}`, data, { headers });
                            } else {
                                // Se não encontrar, faz um POST para criar uma nova entrada
                                await axios.post(`${config.baseUrl}schedules`, data, { headers });
                            }
                        }
                    }
                    const newContact = {
                        "properties": {
                          "email": email,
                          "firstname": clubName,
                          "lastname": "",
                          "phone": phone,
                          "address": fiscalAddress,
                        }
                      }
                    //await createContact(newContact);
                    //window.location.href = '/';
                } else {
                    throw new Error('Erro ao criar club.');
                }
            } else {
                throw new Error('Erro ao registar utilizador.');
            }

        } catch (error) {
            throw new Error(error);
        }
    };

    const TopBar = () => {
        return (
            <div className='ok2land__top-bar'>
                <div className={`ok2land__tb-items ${ step === 3 ? 'item-active-half' : ''} ${ step === 4 ? 'item-active' : ''} `}>
                    <span className={`ok2land__tbi-numbers ${ step > 2 && step < 5 ? 'ok2land__tbin-active' : '' }`}>1</span>
                    <span className={`ok2land__tbi-title ${ step > 2 && step < 5 ? 'ok2land__tbit-active' : '' }`}>AIRFIELD</span>
                </div>
                <div className={`ok2land__tb-items ${ step === 5 ? 'item-active' : ''} `}>
                    <span className={`ok2land__tbi-numbers ${ step === 5 ? 'ok2land__tbin-active' : '' }`}>2</span>
                    <span className={`ok2land__tbi-title ${ step === 5 ? 'ok2land__tbit-active' : '' }`}>RUNWAY</span>
                </div>
                <div className={`ok2land__tb-items ${ step === 6 ? 'item-active-half' : ''} ${ step === 7 ? 'item-active' : ''} `}>
                    <span className={`ok2land__tbi-numbers ${ step > 5 && step < 8 ? 'ok2land__tbin-active' : '' }`}>3</span>
                    <span className={`ok2land__tbi-title ${ step > 5 && step < 8 ? 'ok2land__tbit-active' : '' }`}>RATES</span>
                </div>
                <div className={`ok2land__tb-items ${ step === 8 ? 'item-active' : ''} `}>
                    <span className={`ok2land__tbi-numbers ${ step === 8 ? 'ok2land__tbin-active' : '' }`}>4</span>
                    <span className={`ok2land__tbi-title ${ step === 8 ? 'ok2land__tbit-active' : '' }`}>PAYMENT</span>
                </div>
            </div>
        );
    };

    /* Render signup page layout */
    return (
        <div className='ok2land__signup-box'>
            <img className='ok2land__logo' src={Ok2LandLogo} alt='Ok2Land'/>
            <form className='signup__form'>
                {step === 1 && (
                    <div className='ok2land__signup-form'>
                        <div className='ok2land__signup-form-box'>
                            <div className='signup-outterForm'>
                                <div className='signup-innerForm'>
                                    <span className='ok2land__signup-title'>WELCOME</span>
                                    <label>Username</label>
                                    <input type="username" id="username" value={username} onChange={event => setUsername(event.target.value)} required/>
                                    <label>Email</label>
                                    <input type="email" id="email" value={email} onChange={event => setEmail(event.target.value)} required/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div className='ok2land__signup-form'>
                        <div className='ok2land__signup-form-box'>
                            <div className='signup-outterForm'>
                                <div className='signup-innerForm'>
                                    <span className='ok2land__signup-title'>WELCOME</span>
                                    <span className='ok2land__signup-text'>Enter password to make your account saffer</span>
                                    <label>Password</label>
                                    <input type="password" id="password" value={password} onChange={handlePasswordChange} required />
                                    <label>Confirm password</label>
                                    <input type="password" id="confirm_password" value={confirmpassword} onChange={handlePasswordConfirm} required />
                                    <span className='ok2land__st-hint'>Make sure your password have at least a <strong className='ok2land__sth-strong'>upper and lower case letter</strong>, a <strong className='ok2land__sth-strong'>number</strong> and a <strong className='ok2land__sth-strong'>symbol</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div className='ok2land__signup-form ok2land_sft'>
                        <div className='ok2land__signup-form-box'>
                            <div className='signup-outterForm'>
                                <div className='signup-innerForm'>
                                    <TopBar />
                                    <div className='ok2land__steps'>
                                        <p className='ok2land__steps-title'>Airfield details</p>
                                        <div className='ok2land__steps-content'>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-10'>
                                                    <label>Airfield name</label>
                                                    <input type="text" placeholder=" " id="club_name" value={clubName} onChange={handleClubName} required/>
                                                </div>
                                                <div className='ok2land__scc-2'>
                                                    <label>ICAO Code</label>
                                                    <input type="text" placeholder=" " id="icao" value={icao} onChange={handleIcao} required/>
                                                </div>
                                            </div>
                                            <label>Fiscal address</label>
                                            <input type="text" placeholder=" " id="fiscal_address" value={fiscalAddress} onChange={handleFiscalAddress} required/>
                                            <label>Tax Number</label>
                                            <input type="text" placeholder=" " id="nif" value={nif} onChange={handleNif} required/>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>Contact person</label>
                                                    <input type="text" placeholder=" " id="contact_person" value={contactPerson} onChange={handleContactPerson} required/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>Phone number</label>
                                                    <input type="text" placeholder=" " id="phone_number" value={phone} onChange={handlePhone} required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div className='ok2land__signup-form ok2land_sft'>
                        <div className='ok2land__signup-form-box'>
                            <div className='signup-outterForm'>
                                <div className='signup-innerForm'>
                                    <TopBar />
                                    <div className='ok2land__steps'>
                                        <p className='ok2land__steps-title'>Open and close time</p>
                                        {schedules.map( (schedule,index)=> (
                                            <div className='ok2land__steps-content' key={index}>
                                                <div className='ok2land__days-week'>
                                                    <div className={schedule.days.includes(0) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>mon</div>
                                                    <div className={schedule.days.includes(1) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>tue</div>
                                                    <div className={schedule.days.includes(2) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>wed</div>
                                                    <div className={schedule.days.includes(3) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>thr</div>
                                                    <div className={schedule.days.includes(4) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>fri</div>
                                                    <div className={schedule.days.includes(5) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>sat</div>
                                                    <div className={schedule.days.includes(6) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>sun</div>
                                                    <button className='btn-delete-schedule' onClick={() => handleDeleteSchedule(index)} type="button"><img src={deleteSchedule} alt='Delete'/></button>
                                                </div>
                                                <div className='ok2land__sc-club'>
                                                    <div className='ok2land__scc-5'>
                                                        <label>Open time</label>
                                                        <input type="time" placeholder=" " id="open_time" value={schedule.openTime} onChange={handleOpenTime} disabled/>
                                                    </div>
                                                    <div className='ok2land__scc-5'>
                                                        <label>Close time</label>
                                                        <input type="time" placeholder=" " id="close_time" value={schedule.closeTime} onChange={handleCloseTime} disabled/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {addScheduleFlag && 
                                            <div className='ok2land__steps-content'>
                                                <div className='ok2land__days-week'>
                                                    <div className={`ok2land__day-week ${selectedDays.includes(0) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(0)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(0)}>mon</div>
                                                    <div className={`ok2land__day-week ${selectedDays.includes(1) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(1)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(1)}>tue</div>
                                                    <div className={`ok2land__day-week ${selectedDays.includes(2) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(2)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(2)}>wed</div>
                                                    <div className={`ok2land__day-week ${selectedDays.includes(3) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(3)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(3)}>thr</div>
                                                    <div className={`ok2land__day-week ${selectedDays.includes(4) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(4)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(4)}>fri</div>
                                                    <div className={`ok2land__day-week ${selectedDays.includes(5) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(5)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(5)}>sat</div>
                                                    <div className={`ok2land__day-week ${selectedDays.includes(6) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(6)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(6)}>sun</div>
                                                </div>
                                                <div className='ok2land__sc-club'>
                                                    <div className='ok2land__scc-5'>
                                                        <label>Open time</label>
                                                        <input type="time" placeholder=" " id="open_time" value={openTime} onChange={handleOpenTime}/>
                                                    </div>
                                                    <div className='ok2land__scc-5'>
                                                        <label>Close time</label>
                                                        <input type="time" placeholder=" " id="close_time" value={closeTime} onChange={handleCloseTime}/>
                                                    </div>
                                                </div>
                                                <button className={`ok2land__btn-add ${ isAddActive ? '' : 'disabled'} `} onClick={addSchedule} disabled={!isAddActive} type="button">Add</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 5 && (
                    <div className='ok2land__signup-form ok2land_sft'>
                        <div className='ok2land__signup-form-box'>
                            <div className='signup-outterForm'>
                                <div className='signup-innerForm'>
                                    <TopBar />
                                    <div className='ok2land__steps'>
                                        <div className='ok2land__steps-content'>
                                            <label>Runway type</label>
                                            <Select styles={selectStyle} onChange={handleRunwayType} options={runwayTypes} />
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>Runway Latitude</label>
                                                    <input type="text" placeholder=" " id="runway_latitude" value={runwayLat} onChange={event => setRunwayLat(event.target.value)} required/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>Runway Longitude</label>
                                                    <input type="text" placeholder=" " id="runway_longitude" value={runwayLong} onChange={event => setRunwayLong(event.target.value)} required/>
                                                </div>
                                            </div>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>Helipad Latitude</label>
                                                    <input type="text" placeholder=" " id="helipad_latitude" value={heliLat} onChange={event => setHeliLat(event.target.value)}/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>Helipad Longitude</label>
                                                    <input type="text" placeholder=" " id="helipad_longitude" value={heliLong} onChange={event => setHeliLong(event.target.value)}/>
                                                </div>
                                            </div>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>Elevation</label>
                                                    <input type="text" placeholder=" " id="elevation" value={elevation} onChange={event => setElevation(event.target.value)} required/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>Length</label>
                                                    <input type="text" placeholder=" " id="length" value={length} onChange={event => setLength(event.target.value)} required/>
                                                </div>
                                                <div className='ok2land__scc-2'>
                                                    <label>Direction</label>
                                                    <input type="text" placeholder=" " id="direction" value={direction} onChange={event => setDirection(event.target.value)} required/>
                                                </div>
                                            </div>
                                            <label>Weight limit</label>
                                            <input type="text" placeholder=" " id="weight_limit" value={weightLimit} onChange={event => setWeightLimit(event.target.value)} required/>
                                            <div className='ok2land__request-land'>
                                                <p>Request to land?</p>
                                                <Toggle
                                                    toggled={requestLand}
                                                    onClick={handleRequestLand}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 6 && (
                    <div className='ok2land__signup-form ok2land_sft'>
                        <div className='ok2land__signup-form-box'>
                            <div className='signup-outterForm'>
                                <div className='signup-innerForm'>
                                    <TopBar />
                                    <div className='ok2land__steps'>
                                        <div className='ok2land__steps-content'>
                                            <p className='ok2land__steps-title'>Price per landing</p>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>Normal Landing</label>
                                                    <input type="number" placeholder=" " id="price05" value={price05} onChange={event => setPrice05(event.target.value)}/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>Touch & Go</label>
                                                    <input type="number" placeholder=" " id="price05_1" value={price05_1} onChange={event => setPrice05_1(event.target.value)}/>
                                                </div>
                                            </div>
                                            {/*
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>{'< 500kg'}</label>
                                                    <input type="number" placeholder=" " id="price05" value={price05} onChange={event => setPrice05(event.target.value)}/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>500kg - 1 tons</label>
                                                    <input type="number" placeholder=" " id="price05_1" value={price05_1} onChange={event => setPrice05_1(event.target.value)}/>
                                                </div>
                                            </div>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>1 - 2 tons</label>
                                                    <input type="number" placeholder=" " id="price1_2" value={price1_2} onChange={event => setPrice1_2(event.target.value)}/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>2 - 3 tons</label>
                                                    <input type="number" placeholder=" " id="price2_3" value={price2_3} onChange={event => setPrice2_3(event.target.value)}/>
                                                </div>
                                            </div>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>3 - 4 tons</label>
                                                    <input type="number" placeholder=" " id="price3_4" value={price3_4} onChange={event => setPrice3_4(event.target.value)}/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>4 tons</label>
                                                    <input type="number" placeholder=" " id="price4" value={price4} onChange={event => setPrice4(event.target.value)}/>
                                                </div>
                                            </div>
                                            */}
                                        </div>
                                        <br/>
                                        <div className='ok2land__steps-content'>
                                            <p className='ok2land__steps-title'>Price per parking</p>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-12'>
                                                    <label>Hours free parking</label>
                                                    <input type="number" placeholder=" " id="priceHoursFree" value={priceHoursFree} onChange={event => setPriceHoursFree(event.target.value)}/>
                                                </div>
                                            </div>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>Price single engine (per hour)</label>
                                                    <input type="number" placeholder=" " id="priceHour" value={priceHourSingle} onChange={event => setPriceHourSingle(event.target.value)}/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>Price twin engine (per hour)</label>
                                                    <input type="number" placeholder=" " id="priceHourTwin" value={priceHourTwin} onChange={event => setPriceHourTwin(event.target.value)}/>
                                                </div>
                                            </div>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>Price bizz jets (per hour)</label>
                                                    <input type="number" placeholder=" " id="priceHourBizz" value={priceHourBizz} onChange={event => setPriceHourBizz(event.target.value)}/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>Price helicopter/rotary (per hour)</label>
                                                    <input type="number" placeholder=" " id="priceHourHeli" value={priceHourHeli} onChange={event => setPriceHourHeli(event.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*
                                        <br/>
                                        <div className='ok2land__steps-content'>
                                            <p className='ok2land__steps-title'>Price overnight</p>
                                            <div className='ok2land__sc-club'>
                                                <div className='ok2land__scc-5'>
                                                    <label>Tie down</label>
                                                    <input type="number" placeholder=" " id="priceOvernight1" value={priceOvernight1} onChange={event => setPriceOvernight1(event.target.value)}/>
                                                </div>
                                                <div className='ok2land__scc-5'>
                                                    <label>Hangar</label>
                                                    <input type="number" placeholder=" " id="priceOvernight2" value={priceOvernight2} onChange={event => setPriceOvernight2(event.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                        */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 7 && (
                    <div className='ok2land__signup-form ok2land_sft'>
                        <div className='ok2land__signup-form-box'>
                            <div className='signup-outterForm'>
                                <div className='signup-innerForm'>
                                    <TopBar />
                                    <div className='ok2land__steps-content'>
                                        <p className='ok2land__steps-title'>Extra services</p>
                                        {services.map((service, index) => (
                                            <div className={selectedServices.some(item => item.id === service.id) ? 'ok2land__extra-service selected' : 'ok2land__extra-service'} key={index}>
                                                <p className={selectedServices.some(item => item.id === service.id) ? 'selected' : ''}><img src={`${config.imagesUrl}${service.attributes.icon.data.attributes.url}`} alt='Back' className={selectedServices.some(item => item.id === service.id) ? 'selected' : ''}/> {service.attributes.name}</p>
                                                {selectedServices.some(item => item.id === service.id) &&
                                                    <div className="ok2land__extra-service-input-container">
                                                        <input type="number" placeholder=" " id={"servicePrice-"+service.id} value={service.price} onChange={event => handleServicePrice(service.id, event.target.value)}/>
                                                        <p>€</p>
                                                    </div>
                                                }
                                                <Toggle
                                                    toggled={selectedServices.some(item => item.id === service.id) ? true : false}
                                                    onClick={() => handleService(service.id)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 8 && (
                    <div className='ok2land__signup-form ok2land_sft'>
                        <div className='ok2land__signup-form-box'>
                            <div className='signup-outterForm'>
                                <div className='signup-innerForm'>
                                    <TopBar />
                                    <div className='ok2land__steps-content'>
                                        <p className='ok2land__steps-title'>Stripe</p>
                                        <label>Stripe Account Email</label>
                                        <input type="text" placeholder=" " id="stripeEmail" value={stripeEmail} onChange={event => setStripeEmail(event.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className={`ok2land__signup-form-bottom-box ${ step > 2 ? 'large-bottom' : ''}`}>
                    <div className='ok2land__signup-form-bottom'>
                        {step < 2 && (
                            <span className='ok2land__signup-signup'>Have an account?<Link to='/'>Log in</Link></span>
                        )}
                        {step > 1 && (
                            <button className='ok2land__back-btn' onClick={checkBackStep} type="button"><img src={LeftArrow} alt='Back'/>BACK</button>
                        )}
                        {step < 8  && (
                        <button id="next__btn" className={`ok2land__btn ${isButtonActive ? 'active-btn' : ''}`} onClick={checkNextStep} type="button" disabled={!isButtonActive}>Continue</button>
                        )}
                        {step === 8 && (
                            <button className={'ok2land__btn active-btn'} onClick={handleSubmit} type="button">Confirm</button>
                        )}
                    </div>
                </div>
            </form>
            <div className='ok2land__signup-image-background'></div>
        </div>
    );
}

export default SignUp;