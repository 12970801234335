import React, { useState } from 'react';
import Ok2LandLogo from '../resources/images/ok2land.png';
import { Link, useHistory, useLocation } from 'react-router-dom';

function Header() {
  const history = useHistory();
  const location = useLocation();

  // Define menu routes
  const menuRoutes = [
    { path: '/movements', name: 'AIRCRAFT MOVEMENTS' },
    { path: '/dashboard', name: 'HISTORY' },
    { path: '/settings', name: 'SETTINGS' },
  ];

  // Convert to lowercase
  const currentPath = location.pathname.toLowerCase();

  // Get current route
  const activeRoute = menuRoutes.find(route => currentPath.startsWith(route.path.toLowerCase()));

  const handleNavItemClick = (route, itemName) => {
    history.push(route);
  };

  return (
    <header>
      <div className="ok2land__header">
        <div className="ok2land__header-inner">
          <div className="ok2land__header-logo">
            <img src={Ok2LandLogo} alt="Logo" />
          </div>
          <div className="ok2land__header-items">
            {menuRoutes.map((route) => (
              <Link
                key={route.path}
                className={`ok2land__header-item ${activeRoute?.path === route.path.toLowerCase() ? 'active' : ''}`}
                to={route.path}
                onClick={() => handleNavItemClick(route.path, route.name)}
              >
                <span>{route.name}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
