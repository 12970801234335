import config from '../global.js';
import axios from 'axios';
import { getUserClubInfo } from './getinfo.js';

// Login function
export async function loginOnOk2land(username, password) {
    try {
        const response = await axios.post(`${config.baseUrl}${config.loginEndpoint}`, {
            identifier: username,
            password: password,
        });
        if (response.status === 200 && response.data.jwt) {
            const token = response.data.jwt;
    
            localStorage.setItem('ok2landJwtToken', token);
            localStorage.setItem('ok2landUserId', response.data.user.id);
            await getUserClubInfo();
            return true;
        } else {
            throw new Error('Error. Check your email and password.');
        }
    } catch (error) {
        throw new Error('Error. Check your email and password.');
    }
};

// Is logged in function
export function isAuthenticated() {
    const token = localStorage.getItem('ok2landJwtToken');
    const userId = localStorage.getItem('ok2landUserId');
  
    if (token && userId) {
      return true;
    } else {
      window.location.href = '/';
      return false;
    }
  }
  

// Logout user
export function logoutFromOk2land() {
    localStorage.removeItem('ok2landJwtToken');
    localStorage.removeItem('ok2landUserId');
}

// Change password
export async function changeOk2landPassword(currentPassword, password, passwordConfirmation) {
    try {
        const token = localStorage.getItem('ok2landJwtToken');
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const response = await axios.post(`${config.baseUrl}${config.changePasswordEndpoint}`, {
            'currentPassword': currentPassword,
            'password': password,
            'passwordConfirmation': passwordConfirmation,
        },
        {
            headers: headers,
        });
        if (response.status === 200) {
            return true;
        } else {
            throw new Error('Error. Try again please.');
        }
    } catch (error) {
        if (error.response) {
            console.error(error.response.data.error.message);
            throw new Error(error.response.data.error.message);
        } else if (error.request) {
            throw new Error('An error occurred. Try again or contact support.');
        } else {
            throw new Error('An error occurred. Try again or contact support.');
        }
    }
}