import React, { useState, useEffect } from 'react';
import { Toggle } from '../helpers/toggle.js';
import Select from 'react-select'
import { getServices, getCategories, getUserClubInfo, getSchedules, getMtows } from '../helpers/getinfo.js';
import { logoutFromOk2land, isAuthenticated, changeOk2landPassword } from '../helpers/authentication.js';
import { updateClubDetails, updateRunwayDetails, updateClubSchedule, updateClubServices, updateMtows, updatePriceParking } from '../helpers/postinfo.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import config from '../global.js';

// Import components
import deleteSchedule from '../resources/images/delete-schedule.png';
import SignOut from '../resources/images/SignOut.svg';
import Plus from '../resources/images/plus.svg';
import Refuel from '../resources/images/refuel.svg';
import Clean from '../resources/images/clean.svg';
import Maintenance from '../resources/images/maintenence.svg';
import RightArrow from '../resources/images/RightLight.svg';
import Stripe from '../resources/images/StripeLogo.svg';

function Settings() {
  useEffect(() => {
    if (!isAuthenticated()) {
      alert('You are not logged in!')
      window.location.href = '/';
    }
  }, []);


  const [selectedOption, setSelectedOption] = useState('Airfield details');

  // Settings screem handled
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  /*    --------  LOGOUT FUNCTION  -------- */
  const handleLogout = async (e) => {
    e.preventDefault();
    try {
        await logoutFromOk2land();
        window.location.href = '/';
    } catch (err) {
        alert(err.message);
    }
  };

  /*    --------  AIRFIELD DETAILS SCREEN  --------    */
  const [formFields, setFormFields] = useState({
    clubName: '',
    icaoCode: '',
    fiscalAddress: '',
    taxNumber: '',
    contactPerson: '',
    phoneNumber: '',
  });
  const [isFormDirty, setFormDirty] = useState(false);

  // Airfield details form change
  const handleInputChange = (field, value) => {
    setFormFields({
      ...formFields,
      [field]: value,
    });
    setFormDirty(true);
  };

  // Airfield details save action
  const saveClubDetails = async (e) => {
    e.preventDefault();
    setPageBlocked(true);
  
    try {
      const success = await updateClubDetails(formFields.clubName, formFields.icaoCode, formFields.fiscalAddress, formFields.taxNumber, formFields.contactPerson, formFields.phoneNumber);
  
      if (success) {
        toast.success('Airfield details updated.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      } else {
        toast.error('An error occurred.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      }
    } catch (err) {
      toast.error('An error occurred.', {
        position: "top-center",
        autoClose: 2000,
        pauseOnHover: false,
      });
    } finally {
      setPageBlocked(false);
      setFormDirty(false);
    }
  };

  /* -------- CHANGE PASSWORD SCREEN -------- */
  const [formFieldsPw, setFormFieldsPw] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // Check form status
  const [isValidPassword, setIsValidPassword] = useState(false);

  // Change password form change
  const handleCurrentPassword = (field, value) => {
    setFormFieldsPw((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleNewPassword = (field, value) => {
    setFormFieldsPw({
      ...formFieldsPw,
      [field]: value,
    });

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+]).*$/;
    const checkPassword = regex.test(value);

    setIsValidPassword(checkPassword);
  };

  const handleComparePassword = (field, value) => {
    setFormFieldsPw({
      ...formFieldsPw,
      [field]: value,
    });
  };

  // Change password save action
  const [isPageBlocked, setPageBlocked] = useState(false);

  const savePassword = async (e) => {
    e.preventDefault();
    let errorMessage = '';
  
    try {
      const success = await changeOk2landPassword(formFieldsPw.currentPassword, formFieldsPw.newPassword, formFieldsPw.confirmPassword);
  
      if (success) {
        setPageBlocked(true);
  
        toast.success('Password changed successfully! Redirecting to login page...', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
          onClose: () => {
            logoutFromOk2land();
            window.location.href = '/';
          },
        });
      } else {
        errorMessage = 'An error occurred. Try again or contact support.';
      }
    } catch (err) {
      console.log(err);
      if (err) {
        errorMessage = err;
      } else {
        errorMessage = 'An error occurred. Try again or contact support.';
      }
  
      const errorMessageElement = document.querySelector('.errorMessage');
      if (errorMessageElement) {
        errorMessageElement.textContent = errorMessage;
        errorMessageElement.style.display = 'block';
      }
    }
  };

  // Toogle password state
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [field]: !prevShowPassword[field],
    }));
  };

  /*    --------  SCHEDULE SCREEN  --------    */
  const [selectedDays, setSelectedDays] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [addScheduleFlag, setAddScheduleFlag] = useState(true);
  const [openTime, setOpenTime] = useState('');
  const [closeTime, setCloseTime] = useState('');
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isAddActive, setIsAddActive] = useState(false);

  const handleDeleteSchedule = (index) => {
    const newArray = [...schedules];
    newArray.splice(index, 1);
    setSchedules(newArray);
    setAddScheduleFlag(true);
  };

  const handleOpenTime = (e) => {
    setOpenTime(e.target.value);
  };

  const handleCloseTime = (e) => {
      setCloseTime(e.target.value);
  };

  useEffect(() => {
      if(selectedDays.length > 0 && openTime !== '' && closeTime !== ''){
          setIsAddActive(true);
      }
      else{
          setIsAddActive(false);
      }
  }, [selectedDays, openTime, closeTime]);

  const selectDayWeek = (i) => {
      if(!schedules.some(schedule => schedule.days.includes(i))){
          if (selectedDays.includes(i)) {
              const newArray = selectedDays.filter(item => item !== i);
              setSelectedDays(newArray);
          } else {
              setSelectedDays([...selectedDays, i]);
          }
      }
  };

  const addSchedule = () => {
      const jsonObject = {'days': selectedDays, 'openTime': openTime, 'closeTime': closeTime};
      var newArray = [...schedules, jsonObject];
      setSchedules(newArray);
      setSelectedDays([]);
      setOpenTime('');
      setCloseTime('');
      if(newArray.some(schedule => schedule.days.includes(0)) && newArray.some(schedule => schedule.days.includes(1)) && newArray.some(schedule => schedule.days.includes(2)) && newArray.some(schedule => schedule.days.includes(3)) && newArray.some(schedule => schedule.days.includes(4)) && newArray.some(schedule => schedule.days.includes(5)) && newArray.some(schedule => schedule.days.includes(6))){
          setIsButtonActive(true);
          setAddScheduleFlag(false);
      }
  };

  // Schedule details save action
  const saveClubSchedule = async (e) => {
    e.preventDefault();
    setPageBlocked(true);
  
    try {
      const success = await updateClubSchedule(schedules);
  
      if (success) {
        toast.success('Schedule updated.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      } else {
        toast.error('An error occurred.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      }
    } catch (err) {
      toast.error('An error occurred.', {
        position: "top-center",
        autoClose: 2000,
        pauseOnHover: false,
      });
    } finally {
      setPageBlocked(false);
      setFormDirty(false);
    }
  };

  function transformScheduleData(data) {
    const schedules = {};

    data.data.forEach(item => {
        const { day_index, open, close } = item.attributes;
        const key = `${open}-${close}`;

        if (!schedules[key]) {
            schedules[key] = { days: [], openTime: open, closeTime: close };
        }
        schedules[key].days.push(day_index);
    });

    return Object.values(schedules);
  }

  /*    --------  RUNWAY SCREEN  --------    */
  const [runwayTypes, setRunwayTypes] = useState([]);
  const [runwayType, setRunwayType] = useState(0);
  const [requestLand, setRequestLand] = useState(false);
  const [formFieldsR, setFormFieldsR] = useState({
    runwayType: '',
    runwayDirection: '',
    runwayLatitude: '',
    runwayLongitude: '',
    runwayElevation: '',
    runwayLength: '',
    runwayWeight: '',
    runwayRequest: '',
  });
  const [isFormDirtyR, setFormDirtyR] = useState(false);

  // Edit runway form change
  const handleInputChangeR = (field, value) => {
    setFormFieldsR({
      ...formFieldsR,
      [field]: value,
    });
    setFormDirtyR(true);
  };

  // Runway details save action
  const saveRunwayDetails = async (e) => {
    e.preventDefault();
    setPageBlocked(true);
  
    try {
      const success = await updateRunwayDetails(formFieldsR.runwayType, formFieldsR.runwayDirection, formFieldsR.runwayLatitude, formFieldsR.runwayLongitude, formFieldsR.runwayElevation, formFieldsR.runwayLength, formFields.runwayWeight, formFields.request);
  
      if (success) {
        toast.success('Runway details updated.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      } else {
        toast.error('An error occurred.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      }
    } catch (err) {
      toast.error('An error occurred.', {
        position: "top-center",
        autoClose: 2000,
        pauseOnHover: false,
      });
    } finally {
      setPageBlocked(false);
      setFormDirtyR(false);
    }
  };

  // Runway save action
  const handleSaveClickR = () => {
    console.log('Saving RUNWAY form data:', formFieldsR);
    setFormDirtyR(false);
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleRequestLand = (state) => {
    setFormFieldsR((prevFields) => ({
      ...prevFields,
      runwayRequest: state,
    }));
  };

  // Get landings info
  useEffect(() => {
    async function fetchData() {

      try {
        // Get categories
        const categoriesData = await getCategories();
        const arrayCategories = categoriesData.data.map((item) => ({
          value: item.id,
          label: capitalizeFirstLetter(item.attributes.name),
        }));
        setRunwayTypes(arrayCategories);
      } catch (error) {
        console.error('Error getting categories info: ', error);
      }

      try {
        // Get user and club info
        const userData = await getUserClubInfo();

        setFormFields((prevFields) => ({
          ...prevFields,
          clubName: userData.name1 || '',
          icaoCode: userData.name2 || '',
          fiscalAddress: userData.address || '',
          taxNumber: userData.nif || '',
          contactPerson: userData.contact_name || '',
          phoneNumber: userData.phone || '',
        }));
        setFormFieldsR((prevFields) => ({
          ...prevFields,
          runwayType: userData.category.id || '',
          runwayTypeName: capitalizeFirstLetter(userData.category.name) || '',
          runwayLatitude: userData.lat || '',
          runwayLongitude: userData.long || '',
          runwayDirection: userData.direction || '',
          runwayElevation: userData.altitude || '',
          runwayLength: userData.length || '',
          runwayWeight: userData.weight || '',
          runwayRequest: userData.request || '',
        }));
        setRequestLand(userData.request);
        setFormFieldsPP((prevFields) => ({
          ...prevFields,
          singleEngine: userData.price_park || '',
          twinEngine: userData.price_park_twin || '',
          bizzJets: userData.price_park_bizz || '',
          helicopter: userData.price_park_heli || '',
          hoursFreeParking: userData.hours_free_park || '',
        }));
      } catch (error) {
        console.error('Error getting user and club info: ', error);
      }

      try {
        // Get schedules
        const schedulesData = await getSchedules();
        if(schedulesData.data.length > 0){
          setSchedules(transformScheduleData(schedulesData));
          setAddScheduleFlag(false);
        }
      } catch (error) {
        console.error('Error getting schedules info: ', error);
      }

      try {
        // Get services
        
        const servicesData = await getServices();
        console.log('services', servicesData);
        if(servicesData.length > 0){
          setServices(servicesData);
        }
      } catch (error) {
        console.error('Error getting services info: ', error);
      }

      try {
        // Get MTOW
        const mtowsData = await getMtows();
        var newArray = [];
        console.log('mtows', JSON.stringify(mtowsData));
        if(mtowsData.data.length > 0){
          mtowsData.data.forEach(item => {
            newArray.push(
              {
                from: item.attributes.weight1,
                to: item.attributes.weight2,
                priceLand: item.attributes.priceLand,
                priceTouchGo: item.attributes.priceTouchGo,
              }
            )
          });
          setDynamicRows(newArray);
        }
      } catch (error) {
        console.error('Error getting MTOWs info: ', error);
      }
    }

    fetchData();
  }, []);


  const handleRunwayType = (value) => {
    setFormFieldsR((prevFields) => ({
      ...prevFields,
      runwayType: value.value,
    }));
    setFormFieldsR((prevFields) => ({
      ...prevFields,
      runwayTypeName: value.label,
    }));
    setFormDirtyR(true);
  };

  const selectStyle = {
    control: (base, state) => ({
        ...base,
        height: '50px',
        minHeight: '50px',
        backgroundColor: state.hasValue ? '#FFFFFF' : '#DBF1F599',
        borderRadius: 2,
        borderWidth: 0,
        marginBottom: 10,
        boxShadow: state.isFocused ? 0 : base.boxShadow,
    }),
    input: (base) => ({
      ...base,
      height: '50px',
      margin: 0,
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '50px',
      padding: '0px 10px',
    }),
  };

  /*    --------  PRICING LANDING (MTOW)  --------    */
  const [trainingLand, setTrainingLand] = useState(false);
  const [isButtonActiveMTOW, setIsButtonActiveMTOW] = useState(false);
  const [dynamicRows, setDynamicRows] = useState([
    {
      from: '0',
      to: '',
      priceLand: '',
      priceTouchGo: '',
    },
  ]);

  const handleAddRow = () => {
    const lastRow = dynamicRows[dynamicRows.length - 1];

    if (!lastRow.to || !lastRow.priceLand || !lastRow.priceTouchGo) {
      setIsButtonActiveMTOW(false);
      alert('Preencha os campos da linha atual.');
      return;
    }

    setIsButtonActiveMTOW(true);

    const newFrom = parseInt(lastRow.to) + 1 || 0;

    setDynamicRows((prevRows) => [
      ...prevRows,
      {
        from: newFrom.toString(),
        to: '',
        priceLand: '',
        priceTouchGo: '',
      },
    ]);
  };

  // Price landing (MTOW) save action
  const saveMtows = async (e) => {
    e.preventDefault();
    setPageBlocked(true);
  
    try {
      const success = await updateMtows(dynamicRows);
  
      if (success) {
        toast.success('Landing Fees updated.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
        
      } else {
        toast.error('An error occurred.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      }
    } catch (err) {
      toast.error('An error occurred.', {
        position: "top-center",
        autoClose: 2000,
        pauseOnHover: false,
      });
    } finally {
      setPageBlocked(false);
      setIsButtonActiveMTOW(false);
    }
  };

  /*    --------  PRICING PARKING  --------    */
  const [formFieldsPP, setFormFieldsPP] = useState({
    hoursFreeParking: '',
    singleEngine: '',
    twinEngine: '',
    bizzJets: '',
    helicopter: '',
  });
  const [isFormDirtyPP, setFormDirtyPP] = useState(false);

  // Price parking form change
  const handleInputChangePP = (field, value) => {
    setFormFieldsPP({
      ...formFieldsPP,
      [field]: value,
    });
    setFormDirtyPP(true);
  };

  // Price parking save action
  const savePriceParking = async (e) => {
    e.preventDefault();
    setPageBlocked(true);
  
    try {
      const success = await updatePriceParking(formFieldsPP.hoursFreeParking, formFieldsPP.singleEngine, formFieldsPP.twinEngine, formFieldsPP.bizzJets, formFieldsPP.helicopter);
  
      if (success) {
        toast.success('Pricing parking updated.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      } else {
        toast.error('An error occurred.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      }
    } catch (err) {
      toast.error('An error occurred.', {
        position: "top-center",
        autoClose: 2000,
        pauseOnHover: false,
      });
    } finally {
      setPageBlocked(false);
      setFormDirtyPP(false);
    }
  };

  /*    --------  EXTRA SERVICES  --------    */
  const [services, setServices] = useState([]);
  const [isFormDirtyES, setFormDirtyES] = useState(false);

  const handleService = (index) => {
    setFormDirtyES(true);
    const updatedServices = [...services];
    if(updatedServices[index].attributes.active) {
      updatedServices[index].attributes.active = false;
      updatedServices[index].attributes.price = '';
    }
    else{
      updatedServices[index].attributes.active = true;
    }
    updatedServices[index].attributes.updated = true;
    setServices(updatedServices);
  };

  const handleServicePrice = (index, value) => {
    setFormDirtyES(true);
    const updatedServices = [...services];
    updatedServices[index].attributes.price = value;
    updatedServices[index].attributes.updated = true;
    setServices(updatedServices);
  };

  // Airfield details save action
  const saveClubServices = async (e) => {
    e.preventDefault();
    setPageBlocked(true);
  
    try {
      const success = await updateClubServices(services);
  
      if (success) {
        toast.success('Extra Services updated.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      } else {
        toast.error('An error occurred.', {
          position: "top-center",
          autoClose: 2000,
          pauseOnHover: false,
        });
      }
    } catch (err) {
      toast.error('An error occurred.', {
        position: "top-center",
        autoClose: 2000,
        pauseOnHover: false,
      });
    } finally {
      setPageBlocked(false);
      setFormDirty(false);
    }
  };

  return (
    <div className='container ok2land__container-settings'>
      {isPageBlocked && <div className="page-blocker"></div>}
      <ToastContainer pauseOnFocusLoss={false} />
      <div className='row ok2land__row-settings'>
        <div className='col-sm-4 col-md-4 right__border'>
          {['Airfield details', 'Change password', 'Opening hours', 'Runway', 'Pricing Landing', 'Pricing Parking', 'Extra services', 'Payments'].map((option, index) => (
            <div key={index} className={`settings-option ${selectedOption === option ? 'selected' : ''}`} onClick={() => handleOptionClick(option)}>
              {option}
            </div>
          ))}
          <div className='ok2land__logout-box' onClick={handleLogout}>
            <img src={SignOut} alt='Delete'/>
            <span>LOGOUT</span>
          </div>
        </div>
        <div className='col-sm-8 col-md-8 options__col'>
          {selectedOption === 'Airfield details' && (
            <div className='ok2land__settings-config-col'>
              <h2 className='ok2land__settings-config-col-title'>AIRFIELD DETAILS</h2>
              <div className='ok2land__settings-config-col-form row'>
                <div className='form-group col-md-9'>
                  <label htmlFor='clubName'>Airfield Name</label>
                  <input type='text' className='form-control' id='clubName' name='clubName' value={formFields.clubName} onChange={(e) => handleInputChange('clubName', e.target.value)} />
                </div>
                <div className='form-group col-md-3'>
                  <label htmlFor='icaoCode'>ICAO Code</label>
                  <input type='text' className='form-control' id='icaoCode' name='icaoCode' value={formFields.icaoCode} onChange={(e) => handleInputChange('icaoCode', e.target.value)} />
                </div>
                <div className='form-group col-md-12'>
                  <label htmlFor='fiscalAddress'>Fiscal Address</label>
                  <input className='form-control' id='fiscalAddress' name='fiscalAddress' value={formFields.fiscalAddress} onChange={(e) => handleInputChange('fiscalAddress', e.target.value)}/>
                </div>
                <div className='form-group col-md-12'>
                  <label htmlFor='taxNumber'>Tax Number</label>
                  <input type='text' className='form-control' id='taxNumber' name='taxNumber' value={formFields.taxNumber} onChange={(e) => handleInputChange('taxNumber', e.target.value)} />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='contactPerson'>Contact Person</label>
                  <input type='text' className='form-control' id='contactPerson' name='contactPerson' value={formFields.contactPerson} onChange={(e) => handleInputChange('contactPerson', e.target.value)} />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='phoneNumber'>Phone Number</label>
                  <input type='text' className='form-control' id='phoneNumber' name='phoneNumber' value={formFields.phoneNumber} onChange={(e) => handleInputChange('phoneNumber', e.target.value)}/>
                </div>
                <button className={`save-button ${isFormDirty ? 'save-active' : ''}`} onClick={saveClubDetails} disabled={!isFormDirty}>
                  SAVE
                </button>
              </div>
            </div>
          )}
          {selectedOption === 'Change password' && (
            <div className='ok2land__settings-config-col'>
              <h2 className='ok2land__settings-config-col-title'>CHANGE PASSWORD</h2>
              <div className='ok2land__settings-config-col-form row'>
                <div className='form-group col-md-12'>
                  <label htmlFor='currentPassword'>Enter current password</label>
                  <div className='password-input-container'>
                    <input
                      type={showPassword.currentPassword ? 'text' : 'password'}
                      className='form-control'
                      id='currentPassword'
                      name='currentPassword'
                      value={formFieldsPw.currentPassword}
                      onChange={(e) => handleCurrentPassword('currentPassword', e.target.value)}
                    />
                    <span className='password-toggle-icon' onClick={() => togglePasswordVisibility('currentPassword')}>
                      {showPassword.currentPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
                <div className='form-group col-md-12'>
                  <label htmlFor='newPassword'>Enter new password</label>
                  <div className='password-input-container'>
                    <input
                      type={showPassword.newPassword ? 'text' : 'password'}
                      className='form-control'
                      id='newPassword'
                      name='newPassword'
                      value={formFieldsPw.newPassword}
                      onChange={(e) => handleNewPassword('newPassword', e.target.value)}
                    />
                    <span className='password-toggle-icon' onClick={() => togglePasswordVisibility('newPassword')}>
                      {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
                <div className='form-group col-md-12'>
                  <label htmlFor='confirmPassword'>Confirm new password</label>
                  <div className='password-input-container'>
                    <input
                      type={showPassword.confirmPassword ? 'text' : 'password'}
                      className='form-control'
                      id='confirmPassword'
                      name='confirmPassword'
                      value={formFieldsPw.confirmPassword}
                      onChange={(e) => handleComparePassword('confirmPassword', e.target.value)}
                    />
                    <span className='password-toggle-icon' onClick={() => togglePasswordVisibility('confirmPassword')}>
                      {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
                <label className='errorMessage'></label>
                <label className='password-hint'>Make sure your password has at least an <strong>upper and lower case letter</strong>, a <strong>number</strong>, and a <strong>symbol</strong>.</label>
                <button className={`save-button ${formFieldsPw.currentPassword && isValidPassword && (formFieldsPw.newPassword === formFieldsPw.confirmPassword) ? 'save-active' : ''}`} onClick={savePassword} disabled={!formFieldsPw.currentPassword || !isValidPassword || !((formFieldsPw.newPassword === formFieldsPw.confirmPassword))}>
                  SAVE
                </button>
              </div>
            </div>
          )}
          {selectedOption === 'Opening hours' && (
            <div className='ok2land__settings-config-col'>
              <h2 className='ok2land__settings-config-col-title'>Operating hours</h2>
              <div className='ok2land__steps'>
                {schedules.map( (schedule,index)=> (
                    <div className='ok2land__steps-content' key={index}>
                        <div className='ok2land__days-week'>
                            <div className={schedule.days.includes(0) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>mon</div>
                            <div className={schedule.days.includes(1) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>tue</div>
                            <div className={schedule.days.includes(2) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>wed</div>
                            <div className={schedule.days.includes(3) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>thr</div>
                            <div className={schedule.days.includes(4) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>fri</div>
                            <div className={schedule.days.includes(5) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>sat</div>
                            <div className={schedule.days.includes(6) ? 'ok2land__day-week selected' : 'ok2land__day-week disabled'}>sun</div>
                            <button className='btn-delete-schedule' onClick={() => handleDeleteSchedule(index)} type="button"><img src={deleteSchedule} alt='Delete'/></button>
                        </div>
                        <div className='ok2land__sc-club'>
                            <div className='ok2land__scc-5'>
                                <label>Opening time</label>
                                <input type="time" placeholder=" " id="open_time" value={schedule.openTime} onChange={handleOpenTime} disabled/>
                            </div>
                            <div className='ok2land__scc-5'>
                                <label>Closing time</label>
                                <input type="time" placeholder=" " id="close_time" value={schedule.closeTime} onChange={handleCloseTime} disabled/>
                            </div>
                        </div>
                    </div>
                ))}
                {addScheduleFlag && 
                  <div className='ok2land__steps-content'>
                      <div className='ok2land__days-week'>
                          <div className={`ok2land__day-week ${selectedDays.includes(0) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(0)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(0)}>mon</div>
                          <div className={`ok2land__day-week ${selectedDays.includes(1) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(1)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(1)}>tue</div>
                          <div className={`ok2land__day-week ${selectedDays.includes(2) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(2)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(2)}>wed</div>
                          <div className={`ok2land__day-week ${selectedDays.includes(3) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(3)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(3)}>thr</div>
                          <div className={`ok2land__day-week ${selectedDays.includes(4) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(4)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(4)}>fri</div>
                          <div className={`ok2land__day-week ${selectedDays.includes(5) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(5)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(5)}>sat</div>
                          <div className={`ok2land__day-week ${selectedDays.includes(6) ? 'selected' : ''} ${schedules.some(schedule => schedule.days.includes(6)) ? 'disabled' : ''}`} onClick={() => selectDayWeek(6)}>sun</div>
                      </div>
                      <div className='ok2land__sc-club'>
                          <div className='ok2land__scc-5'>
                              <label>Open time</label>
                              <input type="time" placeholder=" " id="open_time" value={openTime} onChange={handleOpenTime}/>
                          </div>
                          <div className='ok2land__scc-5'>
                              <label>Close time</label>
                              <input type="time" placeholder=" " id="close_time" value={closeTime} onChange={handleCloseTime}/>
                          </div>
                      </div>
                      <button className={`ok2land__btn-add ${ isAddActive ? '' : 'disabled'} `} onClick={addSchedule} disabled={!isAddActive} type="button">Add</button>
                  </div>
                }
              </div>
              <button className={`save-button ${isButtonActive ? 'save-active' : ''}`} onClick={saveClubSchedule} disabled={!isButtonActive}>
                SAVE
              </button>
            </div>
          )}
          {selectedOption === 'Runway' && (
            <div className='ok2land__settings-config-col'>
              <h2 className='ok2land__settings-config-col-title'>RUNWAY</h2>
              <div className='ok2land__settings-config-col-form row'>
                <div className='form-group col-md-9'>
                  <div className='ok2land__steps'>
                    <div className='ok2land__steps-content'>
                        <label>Runway type</label>
                        <Select styles={selectStyle} onChange={handleRunwayType} options={runwayTypes} defaultValue={{ label: formFieldsR.runwayTypeName, value: formFieldsR.runwayType }} />
                    </div>
                  </div>
                  <div className='form-group col-md-3'>
                    <label htmlFor='runwayDirection'>Direction</label>
                    <input type='text' className='form-control' id='runwayDirection' name='runwayDirection' value={formFieldsR.runwayDirection} onChange={(e) => handleInputChangeR('runwayDirection', e.target.value)} />
                  </div>
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='runwayLatitude'>Runway Latitude</label>
                  <input type='text' className='form-control' id='runwayLatitude' name='runwayLatitude' value={formFieldsR.runwayLatitude} onChange={(e) => handleInputChangeR('runwayLatitude', e.target.value)} />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='runwayLongitude'>Runway Longitude</label>
                  <input type='text' className='form-control' id='runwayLongitude' name='runwayLongitude' value={formFieldsR.runwayLongitude} onChange={(e) => handleInputChangeR('runwayLongitude', e.target.value)} />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='runwayElevation'>Elevation</label>
                  <input type='text' className='form-control' id='runwayElevation' name='runwayElevation' value={formFieldsR.runwayElevation} onChange={(e) => handleInputChangeR('runwayElevation', e.target.value)} />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='runwayLength'>Length</label>
                  <input type='text' className='form-control' id='runwayLength' name='runwayLength' value={formFieldsR.runwayLength} onChange={(e) => handleInputChangeR('runwayLength', e.target.value)} />
                </div>
                <div className='form-group col-md-12'>
                  <label htmlFor='runwayWeight'>Weight limit</label>
                  <input type='text' className='form-control' id='runwayWeight' name='runwayWeight' value={formFieldsR.runwayWeight} onChange={(e) => handleInputChangeR('runwayWeight', e.target.value)} />
                </div>
                <div className='form-group same-row col-md-12'>
                  <div className='ok2land__request-land'>
                    <label className='request_title'>Request to land?</label>
                      <Toggle
                          toggled={requestLand}
                          onClick={handleRequestLand}
                      />
                  </div>
                </div>
                <button className={`save-button ${isFormDirtyR ? 'save-active' : ''}`} onClick={saveRunwayDetails} disabled={!isFormDirtyR}>
                  SAVE
                </button>
              </div>
            </div>
          )}
          {selectedOption === 'Pricing Landing' && (
            <div className='ok2land__settings-config-col'>
              <h2 className='ok2land__settings-config-col-title'>LANDING FEE BASED ON MTOW</h2>
              <div className='row pricing-title-row'>
                <div className='ok2land__landing-col-title col-from'>
                  <span>From</span>
                </div>
                <div className='ok2land__landing-col-title col-to'>
                  <span>To</span> 
                </div>
                <div className='ok2land__landing-col-title col-md-4'>
                  <span>Price Landing</span> 
                </div>
                <div className='ok2land__landing-col-title col-md-4'>
                  <span>Price Touch & Go</span> 
                </div>
              </div>
              {dynamicRows.map((row, index) => (
                <div key={index} className='row pricing-input-row'>
                  <div className={`ok2land__landing-col-input col-from from-input${!row.from ? ' empty-field' : ''}`}>
                    <span>{row.from}<bold className="bold-from">kg</bold></span>
                  </div>
                  <div className={`ok2land__landing-col-input col-to to-input${!row.to ? ' empty-field' : ''}`}>
                    <input
                      type='text'
                      value={row.to}
                      onChange={(e) => {
                        const value = parseInt(e.target.value) || '';
                        setDynamicRows((prevRows) => {
                          const updatedRows = [...prevRows];
                          updatedRows[index].to = value;
                          setIsButtonActiveMTOW(true);
                          return updatedRows;
                        });
                      }}
                    />
                  </div>
                  <div className={`ok2land__landing-col-input col-md-4 cost-input${!row.priceLand ? ' empty-field' : ''}`}>
                    <input
                      type='text'
                      value={row.priceLand}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value) || '';
                        setDynamicRows((prevRows) => {
                          const updatedRows = [...prevRows];
                          updatedRows[index].priceLand = value;
                          setIsButtonActiveMTOW(true);
                          return updatedRows;
                        });
                      }}
                    />
                  </div>
                  <div className={`ok2land__landing-col-input col-md-4 cost-input${!row.priceTouchGo ? ' empty-field' : ''}`}>
                    <input
                      type='text'
                      value={row.priceTouchGo}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value) || '';
                        setDynamicRows((prevRows) => {
                          const updatedRows = [...prevRows];
                          updatedRows[index].priceTouchGo = value;
                          setIsButtonActiveMTOW(true);
                          return updatedRows;
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
              <div className='row'>
                <div className='col-md-12 row-add-mtow' onClick={handleAddRow}>
                  <div className='add-mtow'>
                    <img src={Plus} alt='Delete'/>
                  </div>
                  <span>Add MTOW</span>
                </div>
              </div>
              {/*}
              <div className='form-group same-row col-md-12'>
                <div className='ok2land__request-land'>
                  <label className='request_title'>Training discount?</label>
                    <Toggle
                        toggled={trainingLand}
                        onClick={handleTrainingLand}
                    />
                </div>
              </div>
              */}
              <button className={`save-button ${isButtonActiveMTOW ? 'save-active' : ''}`} onClick={saveMtows} disabled={!isButtonActiveMTOW}>
                SAVE
              </button>
            </div>
          )}
          {selectedOption === 'Pricing Parking' && (
            <div className='ok2land__settings-config-col'>
              <h2 className='ok2land__settings-config-col-title'>PARKING FEES</h2>
              <div className='row pricing-parking-row'>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking'>
                  <span className='pricing-parking-title'></span>
                </div>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking'>
                  <span className='pricing-parking-subtitle'>Cost per hour</span>
                </div>
              </div>
              <div className='row pricing-parking-row'>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking'>
                  <span className='pricing-parking-subtitle'>Hours free parking</span>
                </div>
                <div className='ok2land__landing-col-title col-md-4'>
                  <input type="text" name="hours_free_parking" value={formFieldsPP.hoursFreeParking} onChange={(e) => handleInputChangePP('hoursFreeParking', e.target.value)}></input>
                </div>
              </div>
              <div className='row pricing-parking-row'>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking'>
                  <span className='pricing-parking-subtitle'>Single engine</span>
                </div>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking-input'>
                  <input type="text" name="single_engine" value={formFieldsPP.singleEngine} onChange={(e) => handleInputChangePP('singleEngine', e.target.value)}></input>
                </div>
              </div>
              <div className='row pricing-parking-row'>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking'>
                  <span className='pricing-parking-subtitle'>Twin engine</span>
                </div>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking-input'>
                  <input type="text" name="twin-engine" value={formFieldsPP.twinEngine} onChange={(e) => handleInputChangePP('twinEngine', e.target.value)}></input>
                </div>
              </div>
              <div className='row pricing-parking-row'>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking'>
                  <span className='pricing-parking-subtitle'>Bizz jets</span>
                </div>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking-input'>
                  <input type="text" name="bizz_jets" value={formFieldsPP.bizzJets} onChange={(e) => handleInputChangePP('bizzJets', e.target.value)}></input>
                </div>
              </div>
              <div className='row pricing-parking-row'>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking'>
                  <span className='pricing-parking-subtitle'>Helicopter/Rotary</span>
                </div>
                <div className='ok2land__landing-col-title col-md-4 pricing-parking-input'>
                  <input type="text" name="helicopter_rotary" value={formFieldsPP.helicopter} onChange={(e) => handleInputChangePP('helicopter', e.target.value)}></input>
                </div>
              </div>
              <button className={`save-button ${isFormDirtyPP ? 'save-active' : ''}`} onClick={savePriceParking} disabled={!isFormDirtyPP}>
                SAVE
              </button>
            </div>
          )}
          {selectedOption === 'Extra services' && (
            <div className='ok2land__settings-config-col'>
              <h2 className='ok2land__settings-config-col-title'>EXTRA SERVICES</h2>
              {services.map((row, index) => (
                <div key={index} className='row parking-per-night'>
                  <div>
                    <img src={`${config.imagesUrl}${row.attributes.icon.data.attributes.url}`}/>
                    <span>{row.attributes.name}</span>
                  </div>
                  <div>
                    <div className={row.attributes.name == 'Refuel' ? 'service-input' : 'service-input-clean'}>
                      <input type='text' value={row.attributes.price} onChange={(e) => handleServicePrice(index, e.target.value)}/>
                    </div>
                    <div className='ok2land__request-land'>
                      <Toggle
                        toggled={row.attributes.active}
                        onClick={() => handleService(index)}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button className={`save-button ${isFormDirtyES ? 'save-active' : ''}`} onClick={saveClubServices} disabled={!isFormDirtyES}>
                SAVE
              </button>
            </div>
          )}
          {selectedOption === 'Payments' && (
            <div className='ok2land__settings-config-col'>
              <h2 className='ok2land__settings-config-col-title'>PAYMENTS</h2>
              <div className='ok2land__settings-config-col-form row'>
                <div className='row ok2land__settings-stripe'>
                  <div>
                    <img src={Stripe} alt="Stripe"/>
                    <span className='span_ppr'>Connect with Stripe</span>
                  </div>
                  <img className='select__stripe' src={RightArrow} alt='Expand' />
                </div>
                <div className='row ok2land__settings-stripe-steps'>
                  <div className='stripe__steps'>
                    <span className='stripe__step-1'>1</span>
                    <span className='stripe__step-2'>Click “Connect with Strip”</span>
                  </div>
                  <div className='stripe__steps'>
                    <span className='stripe__step-1'>2</span>
                    <span className='stripe__step-2'>Follow Stripe account process</span>
                  </div>
                  <div className='stripe__steps'>
                    <span className='stripe__step-1'>3</span>
                    <span className='stripe__step-2'>Press “complete” after completing your Stripe account integration</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  
}

export default Settings;
