import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './global.js';

// Import pages
import AircraftMovements from '../src/pages/aircraft-movements';
import Dashboard from '../src/pages/dashboard';
import Settings from '../src/pages/settings';
import SignIn from '../src/pages/sign-in.js';
import SignUp from './pages/signup.js';

// Import components
import Header from '../src/components/header';
import '../src/resources/styles/index.scss';

function App() {
  return (
    <div className="ok2land__main-screen">
      <Router>
        <Switch>
          <Route path="/" exact component={SignIn} />
          <Route path="/welcome" exact component={SignUp} />
          <Route path="/movements"><Header /><AircraftMovements /></Route>
          <Route path="/dashboard"><Header /><Dashboard /></Route>
          <Route path="/settings"><Header /><Settings /></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
