import config from '../global.js';
import axios from 'axios';

// Get User info + club
export async function getUserClubInfo() {
    const token = localStorage.getItem('ok2landJwtToken');
    const userId = localStorage.getItem('ok2landUserId');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    try {
      const response = await axios.get(`${config.baseUrl}users/${userId}?populate[clubOwner][populate][category]=true`, { headers });
      const clubData = response.data.clubOwner;
      localStorage.setItem('ok2landUserClub', clubData.id);
      return clubData; 
    } catch (error) {
      console.error('Error on GET request:', error);
      throw new Error('Ocorreu um erro ao carregar as informações.');
    }
  }

// Get Landings function
export async function getLandings() {

    const token = localStorage.getItem('ok2landJwtToken');
    const club = localStorage.getItem('ok2landUserClub');
    const today = new Date().toISOString().split('T')[0];
    console.log('today', today);
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    try {
        const response = await axios.get(`${config.baseUrl}landings?filters[club][id][$eq]=${club}&filters[date][$gte]=${today}&populate[club_services][populate]=*&populate[club][populate]=*&populate[aircraft][populate]=*&populate[user][populate]=*&populate[from][populate]=*&pagination[limit]=100`, { headers });
        return response.data;
    } catch (error) {
        console.error('Erro.', error);
        throw new Error('Ocorreu um erro ao carregar as informações.');
    }
};


// Get Landings function
export async function getHistoryLandings() {
    const token = localStorage.getItem('ok2landJwtToken');
    const club = localStorage.getItem('ok2landUserClub');
    
    // Obtém a data atual e ajusta para meia-noite, garantindo a inclusão de todo o dia de hoje
    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999); // Ajusta para o final do dia
    const todayISO = endOfToday.toISOString().split('T')[0]; // Converte para string ISO, mantendo apenas a data
    
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    try {
        // Ajusta a requisição para incluir landings de hoje e dias anteriores
        const response = await axios.get(`${config.baseUrl}landings?filters[club][id][$eq]=${club}&filters[date][$lte]=${todayISO}&filters[status][$eq]=finished&sort[0]=date:desc&sort[1]=time:desc&populate[club_services][populate]=*&populate[aircraft][populate]=*&populate[user][populate]=*&populate[from][populate]=*&pagination[limit]=100`, { headers });
        return response.data;
    } catch (error) {
        console.error('Erro.', error);
        throw new Error('Ocorreu um erro ao carregar as informações.');
    }
};

// Get all users
export async function getUsers() {

    const token = localStorage.getItem('ok2landJwtToken');
    const club = localStorage.getItem('ok2landUserClub');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    try {
        const response = await axios.get(`${config.baseUrl}users`, { headers });
        return response.data;
    } catch (error) {
        console.error('Erro.', error);
        throw new Error('Ocorreu um erro ao carregar as informações.');
    }
};

// Get all airplanes
export async function getAirplanes() {

    const token = localStorage.getItem('ok2landJwtToken');
    const club = localStorage.getItem('ok2landUserClub');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    try {
        const response = await axios.get(`${config.baseUrl}aircrafts`, { headers });
        return response.data;
    } catch (error) {
        console.error('Erro.', error);
        throw new Error('Ocorreu um erro ao carregar as informações.');
    }
};

// Get Services function
export async function getServices() {
    const token = localStorage.getItem('ok2landJwtToken');
    const club = localStorage.getItem('ok2landUserClub');
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    try {
        const response = await axios.get(`${config.baseUrl}services?populate=*&sort=name`);
        const services = response.data.data;
        for (let i = 0; i < services.length; i++) {
            const response2 = await axios.get(`${config.baseUrl}club-services?filters[club][id][$eq]=${club}&filters[service][id][$eq]=${services[i].id}`, { headers });
            const clubService = response2.data.data;
            if(clubService.length > 0){
                services[i].attributes.clubServiceId = clubService[0].id;
                services[i].attributes.price = clubService[0].attributes.price;
                services[i].attributes.active = true;
            }
            else{
                services[i].attributes.active = false;
            }
        }
        return services;
    } catch (error) {
        console.error('Erro na solicitação GET:', error);
        throw new Error('Ocorreu um erro ao carregar as informações.');
    }
};

// Get Categories (Runway Types) function
export async function getCategories() {
    try {
        const response = await axios.get(`${config.baseUrl}categories`);
        return response.data;
    } catch (error) {
        console.error('Erro na solicitação GET:', error);
        throw new Error('Ocorreu um erro ao carregar as informações.');
    }
};


// Get Schedules function
export async function getSchedules() {
    const token = localStorage.getItem('ok2landJwtToken');
    const club = localStorage.getItem('ok2landUserClub');
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    try {
        const response = await axios.get(`${config.baseUrl}schedules?filters[club][id][$eq]=${club}`, { headers });
        return response.data;
    } catch (error) {
        console.error('Erro na solicitação GET:', error);
        throw new Error('Ocorreu um erro ao carregar as informações.');
    }
};

// Get MTOWs function
export async function getMtows() {
    const token = localStorage.getItem('ok2landJwtToken');
    const club = localStorage.getItem('ok2landUserClub');
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    try {
        const response = await axios.get(`${config.baseUrl}mtows?filters[club][id][$eq]=${club}`, { headers });
        return response.data;
    } catch (error) {
        console.error('Erro na solicitação GET:', error);
        throw new Error('Ocorreu um erro ao carregar as informações.');
    }
};
